import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { getUserProfileReq } from 'api/endpoints/auth';
import UserProfile from 'api/types/auth/UserProfile.interface';
import { ROUTES } from 'constants/routes';
import { STRINGS } from 'constants/strings';
import { setUserProfile } from 'redux/authReducer';
import { selectIsLoggedIn } from 'redux/selectors/auth';
import { getToastOptions } from 'utils/getToastOptions';
import { getUserPermissions } from 'utils/getUserPermissions';

export const useGetUserProfilePermissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const checkPermissions = (profile: UserProfile) => {
    const hasReadPermissions = getUserPermissions(profile).read;

    if (hasReadPermissions) {
      dispatch(setUserProfile(profile));
    } else {
      navigate(ROUTES.ROOT, { replace: true });

      toast.error(STRINGS.SIGN_IN.PERMISSIONS, {
        ...getToastOptions(),
        toastId: 'permissions',
        className: 'toast-lg',
      });
    }
  };

  const { isFetching, refetch: getUserProfile } = useQuery(['authUser'], getUserProfileReq, {
    enabled: false,
    onSuccess: (data) => checkPermissions(data),
  });

  return {
    isLoggedIn,
    getUserProfile,
    isLoading: isFetching,
  };
};
