import { APP_COOKIES } from 'constants/appCookies';
import { appCookie } from 'utils/cookies';

export const generateAuthState = () => {
  const state = String(Date.now());
  appCookie.setCookie(APP_COOKIES.SESSION_STATE, state);
  return state;
};

export const validateState = (state: string) => {
  const currentState = appCookie.getCookie(APP_COOKIES.SESSION_STATE);

  if (!state || !currentState) return false;

  return state === currentState;
};
