export const API = {
  RAK_ID: {
    AUTH: '/auth2/auth',
    REFRESH_TOKEN: '/auth2/token',
    USER_PROFILE: '/users2/me',
    LOGOUT: '/auth2/logout',
  },
  HOTSPOTS: {
    SHADOW_DATA: '/hotspots',
    SEARCH: '/hotspots/search',
    UPDATE_MEMO: '/hotspots/memo',
    OVERVIEW: '/hotspots/overview',
    CREDENTIALS: '/hotspots/:id/ssh',
    BLOCKCHAIN: '/hotspots/:id/blockchain',
  },
  AUTH: {
    LOGIN: '/auth/login',
  },
};
