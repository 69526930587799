import { FocusEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { searchHotspotReq } from 'api/endpoints/hotspots';
import { EVENT_KEYS } from 'constants/eventKeys';
import { STRINGS } from 'constants/strings';
import {
  SEARCH_PARAM_KEY,
  SEARCH_VALIDATION,
} from 'pages/Dashboard/components/HotspotTable/constants';

type Field = HTMLTextAreaElement | HTMLInputElement;

const { HOTSPOTS } = STRINGS;

const INITIAL_VALUE = '';

export const useHotspotTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get(SEARCH_PARAM_KEY);

  const [searchValue, setSearchValue] = useState<string>(search ?? INITIAL_VALUE);
  const [searchError, toggleSearchError] = useState<string>(INITIAL_VALUE);

  const { data, isFetching } = useQuery(
    ['searchHotspot', search],
    () => searchHotspotReq({ search }),
    { enabled: Boolean(search) },
  );

  const handleClearError = useCallback(() => toggleSearchError(INITIAL_VALUE), []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.key === EVENT_KEYS.ENTER) {
        if (!searchError) {
          setSearchParams({ [SEARCH_PARAM_KEY]: e.target.value });
        }
      }
    },
    [searchError, setSearchParams],
  );

  const handleClearSearch = useCallback(() => {
    setSearchValue(INITIAL_VALUE);
    if (searchError) handleClearError();
  }, [searchError, handleClearError]);

  const validate = useCallback((e: KeyboardEvent<Field> | FocusEvent<Field>) => {
    const value = e.target.value;
    const length = value.length;
    setSearchValue(value);

    const isValid =
      (length > SEARCH_VALIDATION.MIN && length < SEARCH_VALIDATION.MAX) || length === 0;

    if (isValid) {
      toggleSearchError(INITIAL_VALUE);
    } else toggleSearchError(HOTSPOTS.SEARCH_ERROR);
  }, []);

  return {
    search,
    validate,
    isFetching,
    searchError,
    handleKeyDown,
    handleClearError,
    handleClearSearch,
    value: searchValue,
    address: data?.address,
  };
};
