import { toast } from 'react-toastify';
import { refreshAccessToken } from 'api/endpoints/refreshAccessToken';
import axios from 'axios';
import { AUTH_COOKIES } from 'constants/authCookies';
import { STATUS_CODE } from 'constants/statusCode';
import { STRINGS } from 'constants/strings';
import { authCookie } from 'utils/cookies';
import { getToastOptions } from 'utils/getToastOptions';

const {
  REACT_APP_RAK_CLIENT_ID: clientId,
  REACT_APP_RAK_ID_API: rakIdUrl,
  REACT_APP_API_URL: baseUrl,
  REACT_APP_SSH_TUNNEL: hotspotUrl,
} = process.env;

interface ApiRequest {
  url?: string;
  skipToast?: boolean;
  withCredentials?: boolean;
}

const axiosInstance = ({ url, withCredentials = false }: Omit<ApiRequest, 'skipToast'>) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials,
  });

const sendApiRequest = ({ url = baseUrl, withCredentials, skipToast }: ApiRequest) => {
  const reqInstance = axiosInstance({ url, withCredentials });

  reqInstance.interceptors.request.use(
    (config) => {
      const accessToken = authCookie.getCookie(AUTH_COOKIES.accessToken);

      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  reqInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status >= STATUS_CODE.INTERNAL_SERVER_ERROR && !skipToast) {
        toast.error(STRINGS.ERROR.SOMETHING_WENT_WRONG, getToastOptions());
      }

      if (
        error.response &&
        error.response.status === STATUS_CODE.UNAUTHORIZED &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        await refreshAccessToken();

        return reqInstance(originalRequest);
      }

      return Promise.reject(error);
    },
  );

  return reqInstance;
};

const authApi = sendApiRequest({});

export { authApi, clientId, hotspotUrl, rakIdUrl, sendApiRequest };
