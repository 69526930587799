import { APP_COOKIES } from 'constants/appCookies';
import { AUTH_COOKIES } from 'constants/authCookies';
import { RAK_ID_AUTH_KEYS } from 'constants/rakId';
import { authCookie } from 'utils/cookies';
import appCookie from 'utils/cookies/appCookie';

export const getSessionParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const state = searchParams.get(RAK_ID_AUTH_KEYS.STATE);
  const accessToken = searchParams.get(RAK_ID_AUTH_KEYS.ACCESS_TOKEN);

  return {
    state,
    accessToken,
  };
};

export const saveSession = ({
  idToken,
  accessToken,
}: {
  idToken?: string;
  accessToken: string;
  refreshToken?: string;
}) => {
  authCookie.setCookie(AUTH_COOKIES.accessToken, accessToken);
  if (idToken) authCookie.setCookie(AUTH_COOKIES.idToken, idToken);
};

export const resetSession = () => {
  authCookie.removeCookie(AUTH_COOKIES.idToken);
  authCookie.removeCookie(AUTH_COOKIES.accessToken);
  authCookie.removeCookie(AUTH_COOKIES.refreshToken);
  authCookie.removeCookie(AUTH_COOKIES.isUserActive);
  appCookie.removeCookie(APP_COOKIES.SESSION_STATE);
};
