import { useCallback, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AUTH_COOKIES } from 'constants/authCookies';
import { STRINGS } from 'constants/strings';
import { useCookieWatcher } from 'hooks/useCookieWatcher';
import { useLogOut } from 'hooks/useLogOut';
import { authCookie } from 'utils/cookies';

import SimpleDialog from 'components/common/SimpleDialog';
import {
  ACTION_DELAY,
  LOGOUT_DELAY,
  TIME_BEFORE_LOGOUT,
} from 'components/modals/AutoLogoutModal/constants';

const AutoLogoutModal = () => {
  const [isModalOpen, toggleModal] = useState<boolean>(false);
  const [isUserActiveValue, setIsUserActiveValue] = useState<string>('');

  const logoutTimer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const { handleLogOut, isLogOutInProgress } = useLogOut();

  const handleUserActivity = useCallback(() => {
    const value = String(isModalOpen ? isUserActiveValue : new Date().getTime());
    authCookie.setCookie(AUTH_COOKIES.isUserActive, value);

    if (!isUserActiveValue) {
      setIsUserActiveValue(value);
    }
  }, [isModalOpen, isUserActiveValue]);

  const handleOpenModal = useCallback(() => {
    logoutTimer.current = setTimeout(async () => {
      await handleLogOut();
    }, LOGOUT_DELAY);

    toggleModal(true);
  }, [handleLogOut]);

  const { reset } = useIdleTimer({
    stopOnIdle: true,
    debounce: ACTION_DELAY,
    onIdle: handleOpenModal,
    timeout: TIME_BEFORE_LOGOUT,
    onAction: handleUserActivity,
  });

  const handleContinueSession = useCallback(() => {
    reset();
    clearTimeout(logoutTimer.current);
    toggleModal(false);
  }, [reset]);

  const handleSetIsUserActive = useCallback(
    (value: string) => {
      setIsUserActiveValue(value);

      if (isModalOpen) {
        handleContinueSession();
      } else {
        reset();
      }
    },
    [handleContinueSession, isModalOpen, reset],
  );

  useCookieWatcher({
    pollingRate: ACTION_DELAY,
    cb: handleSetIsUserActive,
    checkedValue: isUserActiveValue,
    cookieKey: AUTH_COOKIES.isUserActive,
  });

  return (
    <>
      {isModalOpen && (
        <SimpleDialog
          isOpen={isModalOpen}
          disabled={isLogOutInProgress}
          onClick={handleContinueSession}
          text={STRINGS.SIGN_IN.AUTO_LOGOUT_TEXT}
          buttonTitle={STRINGS.SIGN_IN.AUTO_LOGOUT_BUTTON}
        />
      )}
    </>
  );
};

export default AutoLogoutModal;
