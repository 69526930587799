import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ReactComponent as AppLogo } from 'assets/icons/logo.svg';
import clsx from 'clsx';
import { ROUTES } from 'constants/routes';

import styles from './styles.module.scss';

interface Props {
  link?: string;
  invert?: boolean;
  className?: string;
}

const Logo = ({ link = ROUTES.ROOT, invert, className }: Props) => {
  const classes = clsx(styles.Logo, className, { [styles.invert]: invert });

  return (
    <Link to={link} className={classes}>
      <AppLogo className={clsx({ invertLogo: invert })} />
      <Typography variant="h5" fontWeight="600" className={styles.LogoText}>
        Portal
      </Typography>
    </Link>
  );
};

export default Logo;
