import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_COOKIES } from 'constants/authCookies';
import { ROUTES } from 'constants/routes';
import { useGetUserProfilePermissions } from 'hooks/useGetUserProfilePermissions';
import { logOut } from 'redux/authReducer';
import { authCookie } from 'utils/cookies';

import Loader from 'components/common/Loader';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { isLoading, isLoggedIn, getUserProfile } = useGetUserProfilePermissions();

  const isLoginPage = pathname === ROUTES.ROOT;

  const redirect = () => {
    const path = !isLoginPage ? pathname + search : ROUTES.HOTSPOTS.ROOT;
    navigate(path);
  };

  const checkUserProfile = () => {
    if (isLoggedIn) {
      redirect();
    } else {
      getUserProfile().then(() => redirect());
    }
  };

  const isLoggedInCookieExist = authCookie.getCookie(AUTH_COOKIES.accessToken);

  useEffect(() => {
    if (!isLoggedInCookieExist) {
      dispatch(logOut());
      navigate(ROUTES.ROOT, { replace: true });
    } else {
      checkUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedInCookieExist]);

  const showLoader = isLoading || (!isLoggedIn && !isLoginPage);

  if (showLoader) return <Loader />;

  return <>{children}</>;
};

export default ProtectedRoute;
