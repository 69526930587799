import { useCallback } from 'react';
import { generateLoginUrl } from 'pages/Login/utils/generateLoginUrl';

export const useLogin = () => {
  const handleLogin = useCallback(() => {
    const url = generateLoginUrl();

    window.location.assign(url.toString());
  }, []);

  return { handleLogin };
};
