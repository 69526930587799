import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from 'redux/store';
import AppRoutes from 'routes';
import theme from 'theme';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <div className="app">
            <ToastContainer theme="colored" hideProgressBar transition={Slide} />
            <AppRoutes />
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
