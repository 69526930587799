import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { logOutReq } from 'api/endpoints/auth';
import { logOut } from 'redux/authReducer';
import { resetSession } from 'utils/session';

export const useLogOut = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading: isLogOutInProgress } = useMutation(logOutReq, {
    onSuccess: () => {
      resetSession();
      dispatch(logOut());
    },
  });

  const handleLogOut = () => mutate();

  return { isLogOutInProgress, handleLogOut };
};
