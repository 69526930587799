import { ReactNode } from 'react';
import Box from '@mui/material/Box';

interface Props {
  index: number;
  value: number;
  children: ReactNode;
}

const TabPanel = ({ value, index, children }: Props) => (
  <div
    role="tabPanel"
    hidden={value !== index}
    id={`tabPanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {value === index && <Box sx={{ pt: 6, maxWidth: 960 }}>{children}</Box>}
  </div>
);

export default TabPanel;
