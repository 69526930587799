import { CircularProgress } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ROUTES } from 'constants/routes';

import { useAppHeader } from 'components/AppHeader/hooks/useAppHeader';
import { getAppMenu } from 'components/AppHeader/utils/getAppMenu';
import Logo from 'components/common/Logo';

import styles from './styles.module.scss';

const AppHeader = () => {
  const {
    isLoading,
    userProfile,
    anchorElNav,
    handleLogOut,
    handleOpenNavMenu,
    handleCloseNavMenu,
  } = useAppHeader();

  const { profilePhoto, name } = { ...userProfile };

  const avatarProps = {
    alt: 'profile',
    src: profilePhoto,
    variant: 'circular' as AvatarProps['variant'],
    sx: { width: 24, height: 24, marginRight: 1, fontSize: 14 },
  };

  return (
    <AppBar position="sticky" sx={{ color: 'var(--white-color)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo className={styles.AppHeaderLogo} invert link={ROUTES.HOTSPOTS.ROOT} />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              color="inherit"
              aria-haspopup="true"
              aria-controls="menu-appbar"
              onClick={handleOpenNavMenu}
              aria-label="account of current user"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {getAppMenu(handleCloseNavMenu, false, true)}
            </Menu>
            <Logo invert link={ROUTES.HOTSPOTS.ROOT} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {getAppMenu(handleCloseNavMenu, true)}
          </Box>

          <div className={styles.AppHeaderProfile}>
            {Boolean(name) ? (
              <Avatar {...avatarProps}>{name?.charAt(0)}</Avatar>
            ) : (
              <Avatar {...avatarProps} />
            )}
            <Typography
              variant="subtitle2"
              sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }}
            >
              {name}
            </Typography>
            <IconButton sx={{ p: 0, color: 'white' }} onClick={handleLogOut}>
              {isLoading ? (
                <CircularProgress size={16} sx={{ color: 'var(--color-white)' }} />
              ) : (
                <LogoutIcon />
              )}
            </IconButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppHeader;
