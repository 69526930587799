import { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import clsx from 'clsx';
import { DASH } from 'constants/common';

import InfoItemTitle from 'components/common/InfoItemTitle';
import { InfoItemTitleProps } from 'components/common/InfoItemTitle/types';

import styles from './styles.module.scss';

interface Props extends InfoItemTitleProps {
  subtitle: string;
  className?: string;
  subtitleSize?: number;
}

const InfoItem = ({
  title,
  subtitle,
  withLink,
  className,
  withTruncate,
  subtitleSize = 16,
}: Props) => {
  const handleCopy = useCallback(() => {
    if (title) navigator.clipboard.writeText(title);
  }, [title]);

  return (
    <div className={clsx(styles.InfoItem, className)}>
      <IconButton disabled={!title} onClick={handleCopy}>
        <CopyIcon width="36px" height="36px" color="var(--secondary-text-color)" />
      </IconButton>
      <div>
        <InfoItemTitle title={title || DASH} withLink={withLink} withTruncate={withTruncate} />
        <Typography color="var(--secondary-text-color)" fontSize={subtitleSize}>
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default InfoItem;
