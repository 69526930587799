import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getHotspotBlockchainDataReq } from 'api/endpoints/hotspots';
import HotspotShadowData from 'api/types/hotspots/HotspotShadowData.interface';
import { convertHotspotData } from 'pages/Dashboard/components/utils/convertHotspotData';
import { getFlattenObject } from 'utils/getFlattenObject';

import InfoItem from 'components/common/InfoItem';
import Loader from 'components/common/Loader';

import styles from './styles.module.scss';

const HotspotBlockchainData = () => {
  const { id } = useParams();

  const { data, isFetching } = useQuery(['hotspotBlockchainData'], async () => {
    return getHotspotBlockchainDataReq(id ?? '');
  });

  const flattenData = data ? getFlattenObject(data) : null;

  const blockchainData = flattenData ? Array.from(new Map(Object.entries(flattenData))) : [];

  if (isFetching) return <Loader />;

  return (
    <div className={styles.BlockchainData}>
      {blockchainData.map((entry) => {
        const [key, value] = entry;
        const title = convertHotspotData(key, value as HotspotShadowData[keyof HotspotShadowData]);

        return (
          <InfoItem
            key={key}
            title={title}
            subtitleSize={11}
            subtitle={key.toUpperCase()}
            className={styles.BlockchainDataItem}
          />
        );
      })}
    </div>
  );
};

export default HotspotBlockchainData;
