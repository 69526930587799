export const getFlattenObject = (
  obj: Record<string, unknown>,
  parent?: string,
): Record<string, unknown> => {
  let res: Record<string, unknown> = {};

  if (obj !== null) {
    for (const [key, value] of Object.entries(obj)) {
      const propName = parent ? `${parent}_${key}` : key;

      if (value === null) {
        res[propName] = null;
      } else if (typeof value === 'object' && !Array.isArray(obj[key])) {
        res = { ...res, ...getFlattenObject(value as Record<string, unknown>, propName) };
      } else {
        res[propName] = value;
      }
    }
  }

  return res;
};
