import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserProfile from 'api/types/auth/UserProfile.interface';

interface AuthState {
  userProfile: UserProfile | null;
  isLoggedIn: boolean;
}

const initialState = {
  isLoggedIn: false,
  userProfile: null,
} as AuthState;

export const AUTH_ACTION_TYPE = 'AUTH';

const authSlice = createSlice({
  name: AUTH_ACTION_TYPE,
  initialState,
  reducers: {
    setUserProfile(state, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload;
      state.isLoggedIn = true;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.userProfile = null;
    },
  },
});

export const { logOut, setUserProfile } = authSlice.actions;

export default authSlice.reducer;
