const TIMESTAMP_LENGTH = 10;

export const isTimestamp = (timestamp: number) => {
  const length = Math.ceil(Math.log10(timestamp + 1));

  return length === TIMESTAMP_LENGTH && new Date(timestamp).getTime() > 0;
};

export const isValidDate = (dateString: string) => {
  const date = new Date(dateString);

  return !dateString.includes(' ') && isFinite(date.getTime());
};

export const convertDate = (dateObject: Date) => {
  const date = dateObject.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const time = dateObject.toLocaleString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
  });

  return `${date} @ ${time} (UTC)`;
};
