import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

import AppHeader from 'components/AppHeader';
import AutoLogoutModal from 'components/modals/AutoLogoutModal';

import styles from './styles.module.scss';

const AppWrapper = () => (
  <>
    <AppHeader />
    <Container maxWidth="xl" className={styles.AppWrapper}>
      <Outlet />
    </Container>
    <AutoLogoutModal />
  </>
);

export default AppWrapper;
