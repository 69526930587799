import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getHotspotShadowDataReq } from 'api/endpoints/hotspots';
import { convertHotspotData } from 'pages/Dashboard/components/utils/convertHotspotData';

import InfoItem from 'components/common/InfoItem';
import Loader from 'components/common/Loader';

import styles from './styles.module.scss';

const PUBLIC_ADDRESS_KEY = 'sn';

const HotspotShadowData = () => {
  const { id } = useParams();

  const { data, isFetching } = useQuery(['hotspotShadowData'], async () => {
    return getHotspotShadowDataReq(id ?? '');
  });

  const shadowData = data ? Array.from(new Map(Object.entries(data))) : [];

  if (isFetching) return <Loader />;

  return (
    <div className={styles.ShadowData}>
      {shadowData.map((entry) => {
        const [key, value] = entry;
        const title = convertHotspotData(key, value);

        return (
          <InfoItem
            key={key}
            title={title}
            subtitleSize={11}
            subtitle={key.toUpperCase()}
            className={styles.ShadowDataItem}
            withTruncate={key === PUBLIC_ADDRESS_KEY}
          />
        );
      })}
    </div>
  );
};

export default HotspotShadowData;
