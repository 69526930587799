import HotspotShadowData from 'api/types/hotspots/HotspotShadowData.interface';
import { DASH } from 'constants/common';
import { convertDate, isTimestamp, isValidDate } from 'utils/dateConverters';

export const convertHotspotData = (
  key: string,
  value: HotspotShadowData[keyof HotspotShadowData],
) => {
  if (isTimestamp(Number(value)) && typeof value === 'number') {
    const dateObject = new Date(value * 1000);

    return convertDate(dateObject);
  }

  if (isValidDate(String(value)) && typeof value === 'string') {
    const dateObject = new Date(value);

    return convertDate(dateObject);
  }

  if (Array.isArray(value)) return value.join(', ');

  return value ? String(value) : DASH;
};
