import { SyntheticEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getHotspotOverviewReq } from 'api/endpoints/hotspots';
import HotspotStatus from 'api/types/hotspots/HotspotStatus.enum';
import { ROUTES } from 'constants/routes';
import { TabIds } from 'pages/Dashboard/components/HotspotDetails/types';
import { selectUserProfile } from 'redux/selectors/auth';
import { getUserPermissions } from 'utils/getUserPermissions';
import { truncateString } from 'utils/truncateString';

export const useHotspotDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const showFullSN = useMediaQuery(theme.breakpoints.up('md'));

  const { data } = useQuery(['hotspotOverview'], async () => getHotspotOverviewReq(id ?? ''));

  const userProfile = useSelector(selectUserProfile);

  const [isPermissionsModalOpen, showPermissionsModal] = useState<boolean>(false);

  const address = data?.address ?? '';

  const href = useHref(`${ROUTES.SSH_TUNNEL}/${address}`);

  const [activeTab, setActiveTab] = useState<TabIds>(TabIds.OVERVIEW);

  const handleTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  const handleGoBack = useCallback(() => {
    const path = `${ROUTES.HOTSPOTS.ROOT}/?search=${address}`;

    navigate(path, { replace: true });
  }, [address, navigate]);

  const openSshTerminal = useCallback(() => {
    const hasWritePermissions = userProfile ? getUserPermissions(userProfile).write : false;

    if (!hasWritePermissions) {
      showPermissionsModal(true);
    } else window.open(href, '_blank');
  }, [href, userProfile]);

  const closePermissionsModal = useCallback(() => showPermissionsModal(false), []);

  const statusColor = data?.status === HotspotStatus.ONLINE ? 'success' : 'error';
  const backButtonText = showFullSN ? address : truncateString(address, 7, 6);

  return {
    data,
    activeTab,
    statusColor,
    handleGoBack,
    backButtonText,
    openSshTerminal,
    handleTabChange,
    closePermissionsModal,
    isPermissionsModalOpen,
    status: data?.status?.toUpperCase(),
  };
};
