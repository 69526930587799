import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode;
}

const RakButton = ({ children, className, ...props }: ButtonProps) => (
  <button className={clsx(styles.button, className)} {...props}>
    {children}
  </button>
);

export default RakButton;
