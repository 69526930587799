import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import HotspotOverviewData from 'api/types/hotspots/HotspotOverview.interface';
import { ReactComponent as ArrowIcon } from 'assets/icons/right-button-arrow.svg';
import { STRINGS } from 'constants/strings';
import { useHotspotOverview } from 'pages/Dashboard/components/HotspotOverview/hooks/useHotspotOverview';
import { convertDate } from 'utils/dateConverters';

import InfoItem from 'components/common/InfoItem';

import styles from './styles.module.scss';

const { HOTSPOTS } = STRINGS;

interface Props {
  data: HotspotOverviewData;
  openSshTerminal: VoidFunction;
}

const HotspotOverview = ({ data, openSshTerminal }: Props) => {
  const { memo, isLoading, helperText, isMemoValidationError, handleChangeMemo, handleSaveMemo } =
    useHotspotOverview(data);

  const { address, lastSeen } = data;

  return (
    <div className={styles.HotspotOverview}>
      <div className={styles.HotspotOverviewInfo}>
        <InfoItem
          withLink
          withTruncate
          title={address}
          subtitle={HOTSPOTS.PUBLIC_ADDRESS}
          className={styles.HotspotOverviewItem}
        />
        <InfoItem title={convertDate(new Date(lastSeen))} subtitle={HOTSPOTS.LAST_SEEN} />
      </div>
      <div className={styles.HotspotOverviewActions}>
        <Box
          className={styles.HotspotOverviewLink}
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <div>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {HOTSPOTS.SSH_TERMINAL}
            </Typography>
            <Typography color="var(--secondary-text-color)" variant="body2">
              {HOTSPOTS.SSH_TERMINAL_TEXT}
            </Typography>
          </div>
          <IconButton onClick={openSshTerminal} className={styles.HotspotOverviewButton}>
            <ArrowIcon width="40px" height="40px" color="var(--primary-color)" />
          </IconButton>
        </Box>
        <div className={styles.HotspotOverviewField}>
          <TextField
            id="memo"
            multiline
            rows={6}
            fullWidth
            value={memo}
            disabled={isLoading}
            helperText={helperText}
            onChange={handleChangeMemo}
            error={isMemoValidationError}
            placeholder={HOTSPOTS.MEMO_PLACEHOLDER}
            InputProps={{
              sx: {
                backgroundColor: 'var(--white-color)',
              },
            }}
          />
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSaveMemo}
            disabled={isLoading || isMemoValidationError}
            sx={{ mt: '16px', minWidth: '150px', minHeight: '40px' }}
          >
            {isLoading ? (
              <CircularProgress size={16} sx={{ color: 'var(--color-white)' }} />
            ) : (
              STRINGS.COMMON.SAVE
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HotspotOverview;
