import { Typography } from '@mui/material';
import { STRINGS } from 'constants/strings';

import styles from './styles.module.scss';

const { NOT_FOUND } = STRINGS;

const NotFound = () => (
  <div className={styles.NotFound}>
    <div className={styles.NotFoundWrapper}>
      <Typography variant="h4">{NOT_FOUND}</Typography>
    </div>
  </div>
);

export default NotFound;
