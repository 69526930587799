import Cookie from './Cookie';

function getCookieDomain() {
  const [topLevel, root] = window.location.hostname.split('.').reverse();
  return `.${root}.${topLevel}`;
}

class AuthCookie extends Cookie {
  constructor() {
    super(getCookieDomain());
  }
}

const authCookie = new AuthCookie();

export default authCookie;
