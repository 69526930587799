import { NavLink } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { ROUTES } from 'constants/routes';
import { STRINGS } from 'constants/strings';

import styles from 'components/AppHeader/styles.module.scss';

export const menuConfig = [
  {
    title: STRINGS.MENU.HOTSPOT,
    link: ROUTES.HOTSPOTS.ROOT,
  },
  {
    title: STRINGS.MENU.OPEN_SEARCH,
    link: EXTERNAL_LINKS.OPEN_SEARCH,
    target: '_blank',
    externalLink: true,
  },
];

const getStyles = (isActive: boolean, withStyle?: boolean) =>
  withStyle ? clsx(styles.AppHeaderNav, { [styles.AppHeaderNavActive]: isActive }) : '';

export const getAppMenu = (
  handleCloseNavMenu: VoidFunction,
  withStyle?: boolean,
  withMenuItem?: boolean,
) =>
  menuConfig.map(({ title, link, target, externalLink }) => {
    const Title = () => <Typography>{title}</Typography>;

    const AppLink = () => (
      <NavLink
        to={link}
        onClick={handleCloseNavMenu}
        className={({ isActive }) => getStyles(isActive, withStyle)}
      >
        <Title />
      </NavLink>
    );

    const ExternalLink = () => (
      <a href={link} target={target} className={getStyles(false, withStyle)}>
        <Title />
      </a>
    );

    const Link = () => (externalLink ? <ExternalLink /> : <AppLink />);

    return withMenuItem ? (
      <MenuItem key={title}>
        <Link />
      </MenuItem>
    ) : (
      <Link key={title} />
    );
  });
