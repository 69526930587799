import { authApi, sendApiRequest } from 'api/apiClient';
import HotspotBlockchainData from 'api/types/hotspots/HotspotBlockchainData.interface';
import HotspotCredentials from 'api/types/hotspots/HotspotCredentials.interface';
import HotspotOverviewData from 'api/types/hotspots/HotspotOverview.interface';
import HotspotShadowData from 'api/types/hotspots/HotspotShadowData.interface';
import UpdateHotspot from 'api/types/hotspots/UpdateHotspot.interface';
import { AxiosRequestConfig } from 'axios';
import { API } from 'constants/api';

export const searchHotspotReq = async (params: AxiosRequestConfig['params']) => {
  const response = await authApi.get<HotspotOverviewData>(API.HOTSPOTS.SEARCH, { params });
  return response.data;
};

export const getHotspotShadowDataReq = async (id: string) => {
  const response = await authApi.get<HotspotShadowData>(`${API.HOTSPOTS.SHADOW_DATA}/${id}`);
  return response.data;
};

export const getHotspotBlockchainDataReq = async (id: string) => {
  const response = await authApi.get<HotspotBlockchainData>(
    API.HOTSPOTS.BLOCKCHAIN.replace(':id', id),
  );
  return response.data;
};

export const getHotspotOverviewReq = async (id: string) => {
  const response = await authApi.get<HotspotOverviewData>(`${API.HOTSPOTS.OVERVIEW}/${id}`);
  return response.data;
};

export const getSshCredentialsReq = async (id: string) => {
  const response = await sendApiRequest({ skipToast: true }).get<HotspotCredentials>(
    API.HOTSPOTS.CREDENTIALS.replace(':id', id),
  );
  return response.data;
};

export const updateMemoReq = async (data: UpdateHotspot) => {
  const response = await authApi.put<string>(API.HOTSPOTS.UPDATE_MEMO, data);
  return response.data;
};
