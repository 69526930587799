import { useEffect } from 'react';
import Cookies from 'js-cookie';

interface UseCookieWatcher {
  cookieKey: string;
  pollingRate: number;
  checkedValue: string;
  cb: (value: string) => void;
}

export const useCookieWatcher = ({
  cb,
  cookieKey,
  checkedValue,
  pollingRate = 250,
}: UseCookieWatcher) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const cookieValue = Cookies.get(cookieKey);

      if (typeof cookieValue !== 'undefined' && cookieValue !== checkedValue) {
        cb(cookieValue);
      }
    }, pollingRate);

    return () => clearInterval(interval);
  });
};
