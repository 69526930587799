import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { STRINGS } from 'constants/strings';
import { SEARCH_PARAM_KEY } from 'pages/Dashboard/components/HotspotTable/constants';
import { useHotspotTable } from 'pages/Dashboard/components/HotspotTable/hooks/useHotspotTable';

import InfoItemTitle from 'components/common/InfoItemTitle';
import Loader from 'components/common/Loader';

import styles from './styles.module.scss';

const { HOTSPOTS } = STRINGS;

const HotspotTable = () => {
  const {
    value,
    search,
    address,
    validate,
    isFetching,
    searchError,
    handleKeyDown,
    handleClearError,
    handleClearSearch,
  } = useHotspotTable();

  const endAdornment = value && (
    <IconButton onClick={handleClearSearch} disabled={isFetching}>
      <CloseIcon width={16} height={16} />
    </IconButton>
  );

  return (
    <>
      <TextField
        fullWidth
        size="small"
        value={value}
        autoComplete="off"
        onFocus={validate}
        onChange={validate}
        id={SEARCH_PARAM_KEY}
        disabled={isFetching}
        helperText={searchError}
        onBlur={handleClearError}
        error={Boolean(searchError)}
        placeholder={HOTSPOTS.SEARCH_PLACEHOLDER}
        InputProps={{
          endAdornment,
          onKeyDown: handleKeyDown,
          startAdornment: <SearchIcon />,
          sx: {
            borderRadius: 16,
            backgroundColor: 'var(--white-color)',
          },
        }}
      />
      {isFetching ? (
        <Loader />
      ) : (
        <div className={styles.HotspotTable}>
          {address && (
            <>
              <Typography
                variant="h6"
                align="center"
                fontWeight="600"
                color="secondary"
                sx={{ marginBottom: 2 }}
              >
                {HOTSPOTS.PUBLIC_ADDRESS}
              </Typography>
              <InfoItemTitle title={address} withRoute withLink />
            </>
          )}
          {!address && search && (
            <Typography variant="h5" align="center">
              {STRINGS.COMMON.NO_RESULTS}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default HotspotTable;
