import { API } from 'constants/api';
import { generateAuthState } from 'pages/Login/utils/authState';
import { isLocalDev } from 'utils/isLocalDev';

const {
  REACT_APP_RAK_CLIENT_ID: clientId,
  REACT_APP_RAK_ID_OAUTH_URL: rakIdAuthUrl,
  REACT_APP_REDIRECT_URI: wisHmUrl,
} = process.env;

export const generateLoginUrl = () => {
  const origin = isLocalDev ? window.location.host : wisHmUrl;

  const url = new URL(API.RAK_ID.AUTH, rakIdAuthUrl);
  url.searchParams.set('client_id', `${clientId}`);
  url.searchParams.set('response_type', 'token');
  url.searchParams.set('redirect_uri', `https://${origin}/cb`);
  url.searchParams.set('state', generateAuthState());

  return url;
};
