import Box from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { STRINGS } from 'constants/strings';
import HotspotBlockchainData from 'pages/Dashboard/components/HotspotBlockchain';
import { useHotspotDetails } from 'pages/Dashboard/components/HotspotDetails/hooks/useHotspotDetails';
import { TabIds } from 'pages/Dashboard/components/HotspotDetails/types';
import HotspotOverview from 'pages/Dashboard/components/HotspotOverview';
import HotspotShadowData from 'pages/Dashboard/components/HotspotShadowData';
import { getTabProps } from 'utils/tabs';

import BackButton from 'components/common/BackButton';
import Loader from 'components/common/Loader';
import SimpleDialog from 'components/common/SimpleDialog';
import TabPanel from 'components/common/TabPanel';

const { HOTSPOTS } = STRINGS;

const HotspotDetails = () => {
  const {
    data,
    status,
    activeTab,
    statusColor,
    handleGoBack,
    backButtonText,
    openSshTerminal,
    handleTabChange,
    closePermissionsModal,
    isPermissionsModalOpen,
  } = useHotspotDetails();

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <div>
          <BackButton text={backButtonText} onClick={handleGoBack}>
            <Chip
              label={status}
              variant="outlined"
              sx={{ marginLeft: 1.5 }}
              color={statusColor as ChipProps['color']}
            />
          </BackButton>
          <Box sx={{ width: '100%', mt: 3 }}>
            <Box>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="tabs">
                <Tab label={HOTSPOTS.TABS.OVERVIEW} {...getTabProps(TabIds.OVERVIEW)} />
                <Tab label={HOTSPOTS.TABS.HOTSPOT} {...getTabProps(TabIds.HOTSPOT)} />
                <Tab label={HOTSPOTS.TABS.BLOCKCHAIN} {...getTabProps(TabIds.BLOCKCHAIN)} />
              </Tabs>
            </Box>
            <TabPanel value={activeTab} index={TabIds.OVERVIEW}>
              <HotspotOverview data={data} openSshTerminal={openSshTerminal} />
            </TabPanel>
            <TabPanel value={activeTab} index={TabIds.HOTSPOT}>
              <HotspotShadowData />
            </TabPanel>
            <TabPanel value={activeTab} index={TabIds.BLOCKCHAIN}>
              <HotspotBlockchainData />
            </TabPanel>
          </Box>

          {isPermissionsModalOpen && (
            <SimpleDialog
              buttonTitle={STRINGS.COMMON.OK}
              isOpen={isPermissionsModalOpen}
              onClick={closePermissionsModal}
              text={STRINGS.SSH_TERMINAL.PERMISSION_DENIED}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HotspotDetails;
