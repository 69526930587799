import { clientId, hotspotUrl, rakIdUrl, sendApiRequest } from 'api/apiClient';
import HotspotCredentials from 'api/types/hotspots/HotspotCredentials.interface';
import { API } from 'constants/api';
import { AUTH_COOKIES } from 'constants/authCookies';
import { authCookie } from 'utils/cookies';

export const getUserProfileReq = async () => {
  const response = await sendApiRequest({ url: rakIdUrl }).get(
    `${API.RAK_ID.USER_PROFILE}/?clientId=${clientId}`,
  );
  return response.data;
};

export const logOutReq = async () => {
  const refreshToken = authCookie.getCookie(AUTH_COOKIES.refreshToken);

  const response = await sendApiRequest({ url: rakIdUrl }).post(API.RAK_ID.LOGOUT, {
    refreshToken,
  });
  return response.data;
};

export const setSshSessionReq = async () => {
  const response = await sendApiRequest({
    url: hotspotUrl,
    withCredentials: true,
  }).post<HotspotCredentials>(API.AUTH.LOGIN);
  return response.data;
};
