export const ROUTES = {
  ROOT: '/',
  AUTH: {
    CB: '/cb',
  },
  HOTSPOTS: {
    ROOT: '/hotspots',
  },
  SSH_TUNNEL: '/ssh-tunnel',
};
