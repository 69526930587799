import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { STRINGS } from 'constants/strings';
import useSshTunnel from 'pages/SshTunnel/hooks/useSshTunnel';

import Logo from 'components/common/Logo';
import AutoLogoutModal from 'components/modals/AutoLogoutModal';

import styles from './styles.module.scss';

const SshTunnel = () => {
  const {
    id,
    xtermRef,
    commandValue,
    handleSubmit,
    handleReconnect,
    handleCloseSocketWrap,
    handleChangeCommandValue,
  } = useSshTunnel();

  return (
    <div className={styles.Terminal}>
      <AppBar position="static" sx={{ color: 'var(--white-color)' }}>
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              paddingTop: { xs: '8px' },
              paddingBottom: { xs: '8px' },
              minHeight: { xs: '80px', md: '64px' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <div className={styles.TerminalHeaderContent}>
              <Logo invert className={styles.TerminalLogo} />
              <Typography variant="h6" className={styles.TerminalTitle}>
                {STRINGS.SSH_TERMINAL.TITLE}
              </Typography>
              <Typography
                noWrap
                variant="body2"
                sx={{
                  ml: 1,
                }}
              >
                {id}
              </Typography>
            </div>
            <div className={styles.TerminalButtons}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                sx={{
                  fontSize: '14px',
                  minWidth: '120px',
                  color: 'rgba(225, 225, 225, 0.7)',
                  borderColor: 'rgba(225, 225, 225, 0.7)',
                  '&:hover': {
                    borderColor: 'rgba(225, 225, 225, 0.4)',
                  },
                }}
                onClick={handleReconnect}
              >
                {STRINGS.SSH_TERMINAL.RECONNECT}
              </Button>
              <IconButton
                onClick={handleCloseSocketWrap}
                className={styles.TerminalClose}
                sx={{
                  border: '1px solid rgba(225, 225, 225, 0.7)',
                  ml: 1,
                  '&:hover': {
                    borderColor: 'rgba(225, 225, 225, 0.4)',
                  },
                }}
              >
                <CloseIcon width={16} height={16} fill="white" />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={styles.TerminalInputWrapper}>
        <form className={styles.TerminalForm} onSubmit={handleSubmit}>
          <Input
            name="command"
            autoComplete="off"
            value={commandValue}
            className={styles.TerminalInput}
            onChange={handleChangeCommandValue}
            placeholder={STRINGS.SSH_TERMINAL.PLACEHOLDER}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!commandValue}
            className={styles.TerminalInputButton}
          >
            {STRINGS.SSH_TERMINAL.SEND}
          </Button>
        </form>
      </div>
      <div className={styles.TerminalBody}>
        <div id="terminal-container" ref={xtermRef} />
      </div>
      <AutoLogoutModal />
    </div>
  );
};

export default SshTunnel;
