import { buttonClasses, createTheme } from '@mui/material';

const palette = {
  primary: {
    main: '#5d16a6',
  },
  secondary: {
    light: 'rgba(93, 98, 102, 0.6',
    main: '#202020',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

const buttonOverride = {
  root: {
    fontSize: '1rem',
    borderRadius: '36px',
    textTransform: 'unset' as const,
  },
  containedPrimary: {
    color: 'white',
    boxShadow: 'none',
    ['&.' + buttonClasses.disabled]: {
      color: 'white',
      opacity: 0.4,
      backgroundColor: palette.primary.main,
    },
  },
  containedSecondary: {
    ['&.' + buttonClasses.disabled]: {
      opacity: 0.5,
      backgroundColor: palette.secondary.main,
    },
  },
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: buttonOverride,
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          padding: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--primary-color)',
            },
          },
        },
      },
    },
  },
  palette,
});

export default theme;
