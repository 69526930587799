import { FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { setSshSessionReq } from 'api/endpoints/auth';
import { getSshCredentialsReq } from 'api/endpoints/hotspots';
import { AxiosError } from 'axios';
import { EVENT_KEYS } from 'constants/eventKeys';
import { STRINGS } from 'constants/strings';
import { CONNECTION_EVENTS, EXIT_COMMAND } from 'pages/SshTunnel/constants';
import io from 'socket.io-client';
import { getToastOptions } from 'utils/getToastOptions';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

const { REACT_APP_SSH_TUNNEL = '' } = process.env;

const useSshTunnel = () => {
  const { id } = useParams();

  const { refetch: handleGetSshCredentials } = useQuery(
    ['sshCredentials'],
    async () => id && getSshCredentialsReq(id),
    {
      onError: (err: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const message = err?.response?.data?.message || err.message;
        toast.error(message, getToastOptions());
      },
      enabled: false,
    },
  );

  const setSshSession = useMutation(setSshSessionReq);

  const xtermRef = useRef<HTMLDivElement>(null);
  const terminalInputValue = useRef('');

  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);
  const [commandValue, setCommandValue] = useState('');
  const [terminalInstance, setTerminalInstance] = useState<Terminal | null>(null);
  const [fitAddonInstance, setFitAddonInstance] = useState<FitAddon | null>(null);

  const handleCloseSocket = useCallback(
    (withWindowClose = false) => {
      if (socket) {
        socket.close();
      }
      if (withWindowClose) {
        window.close();
      }
    },
    [socket],
  );

  const handleCloseSocketWrap = useCallback(() => {
    handleCloseSocket(true);
  }, [handleCloseSocket]);

  const handleExitTerminal = useCallback(
    (command: string) => {
      if (command === EXIT_COMMAND) {
        handleCloseSocketWrap();
      }
    },
    [handleCloseSocketWrap],
  );

  const handleCheckValue = useCallback((value: string) => {
    if (value?.match(/<[^>]*>/g)) {
      toast.error(STRINGS.SSH_TERMINAL.FORBIDDEN_INPUT_FORMAT, getToastOptions());
      return false;
    }
    return true;
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (commandValue && socket) {
        if (!handleCheckValue(commandValue)) return;

        socket.emit(CONNECTION_EVENTS.DATA, `${commandValue}\r`);
        setCommandValue('');
        handleExitTerminal(commandValue);
      }
    },
    [commandValue, handleCheckValue, handleExitTerminal, socket],
  );

  const handleConnectSocket = async () => {
    setSshSession.mutate(undefined, {
      onError: () => {
        toast.error(STRINGS.SSH_TERMINAL.SSH_CONNECTION_ERROR, getToastOptions());
      },
      onSuccess: () => {
        const newSocket: SocketIOClient.Socket = io(REACT_APP_SSH_TUNNEL, {
          path: '/ssh/socket.io',
        });

        setSocket(newSocket);
      },
    });
  };

  useEffect(() => {
    handleConnectSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReconnect = async () => {
    handleCloseSocket();
    terminalInstance?.clear();
    await handleConnectSocket();
  };

  useEffect(() => {
    if (socket) {
      const closeConnection = () => socket?.close();
      window.addEventListener('beforeunload', closeConnection);
      return () => window.removeEventListener('beforeunload', closeConnection);
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on(CONNECTION_EVENTS.DATA, (socketData) => {
        terminalInstance?.write(socketData);
      });
    }
  }, [socket, terminalInstance]);

  useEffect(() => {
    if (socket) {
      socket.on(CONNECTION_EVENTS.STATUS, (data) => toast.info(data, getToastOptions()));

      socket.on(CONNECTION_EVENTS.SSH_ERROR, (data) => toast.error(data, getToastOptions()));

      socket.on(CONNECTION_EVENTS.ERROR, (data) => {
        if (data === 'authentication_failed') {
          toast.error(STRINGS.SSH_TERMINAL.SSH_CONNECTION_ERROR, getToastOptions());
        } else {
          toast.error(data, getToastOptions());
        }
      });
    }

    return () => socket?.close();
  }, [socket]);

  useEffect(() => {
    if (!terminalInstance && xtermRef.current) {
      const terminal = new Terminal();
      const fitAddon = new FitAddon();
      const terminalContainer = xtermRef.current;
      terminal.loadAddon(fitAddon);
      terminal.open(terminalContainer);
      terminal.focus();
      fitAddon.fit();
      setTerminalInstance(terminal);
      setFitAddonInstance(fitAddon);
    }
  }, [setFitAddonInstance, setTerminalInstance, terminalInstance, xtermRef]);

  useEffect(() => {
    if (fitAddonInstance) {
      const resizeTerminal = () => fitAddonInstance.fit();
      window.addEventListener('resize', resizeTerminal);

      return () => window.removeEventListener('resize', resizeTerminal);
    }
  }, [fitAddonInstance]);

  useEffect(() => {
    if (terminalInstance && socket) {
      if (!id) {
        return;
      }

      const setConnection = async () => {
        const { data: credentials } = await handleGetSshCredentials();

        if (credentials) {
          const value = {
            ip: credentials?.ip,
            port: credentials?.port,
            password: credentials?.password,
            username: credentials?.username,
          };
          const timeoutTime = 1000;

          setTimeout(() => {
            socket.emit(CONNECTION_EVENTS.LOGIN, JSON.stringify(value));

            terminalInstance.onData((data: string) => {
              if (!handleCheckValue(data)) return;

              socket.emit(CONNECTION_EVENTS.DATA, data);
              handleExitTerminal(data);
            });

            terminalInstance.onKey(({ key, domEvent: evt }) => {
              const printable = !evt.altKey && !evt.ctrlKey && !evt.metaKey;

              if (evt.code === EVENT_KEYS.ENTER) {
                if (!handleCheckValue(terminalInputValue.current)) return;

                handleExitTerminal(terminalInputValue.current);
                terminalInputValue.current = '';
              } else if (evt.code === EVENT_KEYS.BACKSPACE) {
                terminalInputValue.current = terminalInputValue.current.slice(0, -1);
              } else if (printable) {
                terminalInputValue.current += key;
              }
            });
          }, timeoutTime);
        }
      };
      setConnection();
    }
  }, [id, socket, terminalInstance, handleCheckValue, handleExitTerminal, handleGetSshCredentials]);

  const handleChangeCommandValue = (event: KeyboardEvent<HTMLInputElement>) => {
    setCommandValue(event.target.value);
  };

  return {
    id,
    xtermRef,
    commandValue,
    handleSubmit,
    handleReconnect,
    handleCloseSocketWrap,
    handleChangeCommandValue,
  };
};

export default useSshTunnel;
