import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLogOut } from 'hooks/useLogOut';
import { selectUserProfile } from 'redux/selectors/auth';

export function useAppHeader() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const { isLogOutInProgress, handleLogOut } = useLogOut();

  const userProfile = useSelector(selectUserProfile);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return {
    anchorElNav,
    userProfile,
    handleLogOut,
    handleOpenNavMenu,
    handleCloseNavMenu,
    isLoading: isLogOutInProgress,
  };
}
