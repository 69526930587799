import { ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as BackIcon } from 'assets/icons/back-button.svg';

import styles from './styles.module.scss';

interface Props {
  text: string;
  children: ReactNode;
  onClick: VoidFunction;
}

const BackButton = ({ text, onClick, children }: Props) => (
  <div className={styles.BackButton}>
    <IconButton onClick={onClick} className={styles.BackButtonControl}>
      <BackIcon width="36px" height="36px" />
    </IconButton>
    <Typography variant="h6" className={styles.BackButtonText}>
      {text}
    </Typography>
    {children}
  </div>
);

export default BackButton;
