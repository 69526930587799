import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { truncateString } from 'utils/truncateString';

import styles from 'components/common/InfoItemTitle/styles.module.scss';
import { InfoItemTitleProps } from 'components/common/InfoItemTitle/types';

export const getTitle = ({ title, withRoute, withTruncate }: InfoItemTitleProps) => {
  const titleComponent = (
    <Typography fontWeight="500" className={styles.InfoItemTitleText}>
      {withTruncate ? truncateString(title, 7, 7) : title}
    </Typography>
  );

  return <>{withRoute ? <Link to={title}>{titleComponent}</Link> : titleComponent}</>;
};
