import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg';
import { EXTERNAL_LINKS } from 'constants/externalLinks';

import { InfoItemTitleProps } from 'components/common/InfoItemTitle/types';
import { getTitle } from 'components/common/InfoItemTitle/utils/getTitle';

import styles from './styles.module.scss';

const InfoItemTitle = ({ title, withRoute, withLink, withTruncate }: InfoItemTitleProps) => {
  return (
    <div className={styles.InfoItemTitle}>
      {getTitle({ title, withRoute, withTruncate })}
      {withLink && (
        <a
          target="_blank"
          className={styles.InfoItemTitleIcon}
          href={`${EXTERNAL_LINKS.HELIUM_HOTSPOTS}/${title}`}
        >
          <LinkIcon />
        </a>
      )}
    </div>
  );
};

export default InfoItemTitle;
