import { RefreshToken } from 'api/types/auth/RefreshToken.interface';
import axios from 'axios';
import { API } from 'constants/api';
import { AUTH_COOKIES } from 'constants/authCookies';
import { logOut } from 'redux/authReducer';
import store from 'redux/store';
import { authCookie } from 'utils/cookies';
import { resetSession, saveSession } from 'utils/session';

const { REACT_APP_RAK_CLIENT_ID: clientId, REACT_APP_RAK_ID_API: rakIdUrl } = process.env;

const rakIdApi = axios.create({
  baseURL: rakIdUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleLogout = () => {
  resetSession();
  store.dispatch(logOut());
};

export const refreshAccessToken = async () => {
  const refreshToken = authCookie.getCookie(AUTH_COOKIES.refreshToken);

  try {
    const response = await rakIdApi.post<RefreshToken>(API.RAK_ID.REFRESH_TOKEN, {
      refreshToken,
      grantType: 'refresh_token',
      clientId,
    });

    const { accessToken, idToken } = response.data;

    if (!accessToken) {
      handleLogout();
    } else {
      saveSession({ accessToken, idToken });
    }

    return response;
  } catch (error) {
    return handleLogout();
  }
};
