import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useGetUserProfilePermissions } from 'hooks/useGetUserProfilePermissions';
import { validateState } from 'pages/Login/utils/authState';
import { getSessionParams } from 'utils/session';

import Loader from 'components/common/Loader';

const Callback = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const navigate = useNavigate();

  const { isLoading, getUserProfile } = useGetUserProfilePermissions();

  useEffect(() => {
    const { accessToken, state } = getSessionParams(search);

    if (!accessToken || !(state && validateState(state))) {
      navigate(ROUTES.ROOT, { replace: true });
    } else {
      getUserProfile().then(() => navigate(ROUTES.HOTSPOTS.ROOT));
    }
  }, [search, navigate, dispatch, getUserProfile]);

  if (isLoading) return <Loader />;

  return null;
};

export default Callback;
