import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import AppWrapper from 'pages/AppWrapper';
import Callback from 'pages/Callback';
import Dashboard from 'pages/Dashboard';
import HotspotDetails from 'pages/Dashboard/components/HotspotDetails';
import HotspotTable from 'pages/Dashboard/components/HotspotTable';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import SshTunnel from 'pages/SshTunnel';

import ProtectedRoute from 'components/ProtectedRoute';

const { HOTSPOTS, AUTH, ROOT, SSH_TUNNEL } = ROUTES;

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROOT}
        element={
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        }
      />
      <Route path={AUTH.CB} element={<Callback />} />
      <Route
        element={
          <ProtectedRoute>
            <AppWrapper />
          </ProtectedRoute>
        }
      >
        <Route path={HOTSPOTS.ROOT} element={<Dashboard />}>
          <Route index element={<HotspotTable />} />
          <Route path=":id" element={<HotspotDetails />} />
        </Route>
      </Route>
      <Route
        path={`${SSH_TUNNEL}/:id`}
        element={
          <ProtectedRoute>
            <SshTunnel />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
