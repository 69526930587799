import { Typography } from '@mui/material';
import { ReactComponent as IntroIcon } from 'assets/icons/intro.svg';
import { ReactComponent as IntroBgIcon } from 'assets/icons/intro-bg.svg';
import { ReactComponent as RakLogo } from 'assets/icons/rakid-logo.svg';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { STRINGS } from 'constants/strings';

import Logo from 'components/common/Logo';
import RakButton from 'components/common/RakButton';

import { useLogin } from './hooks/useLogin';

import styles from './styles.module.scss';

const { SIGN_IN } = STRINGS;

function Login() {
  const { handleLogin } = useLogin();

  return (
    <div className={styles.Login}>
      <div className={styles.LoginContent}>
        <div className={styles.LoginContentInner}>
          <Logo />
          <div className={styles.LoginContentButtonWrapper}>
            <Typography variant="h4">{SIGN_IN.ACCOUNT}</Typography>
            <RakButton onClick={handleLogin} className={styles.LoginContentButton}>
              <RakLogo />
              {SIGN_IN.RAK_ID}
            </RakButton>
            <Typography variant="body1" color="var(--secondary-text-color)">
              {SIGN_IN.RAK_DESCRIPTION}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={EXTERNAL_LINKS.RAK_ID}
                className={styles.LoginContentLink}
              >
                RAK ID
              </a>
              .
            </Typography>
          </div>
          <Typography className={styles.LoginContentCopyright} color="var(--secondary-text-color)">
            {SIGN_IN.COPYRIGHT}
          </Typography>
        </div>
      </div>
      <div className={styles.LoginIntro}>
        <div className={styles.LoginIntroContent}>
          <div className={styles.LoginIntroBg}>
            <IntroIcon />
            <IntroBgIcon className={styles.LoginIntroIcon} />
          </div>
          <Typography variant="h5" color="white" align="center" fontWeight="500">
            {SIGN_IN.DESCRIPTION}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Login;
