import { KeyboardEvent, useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMemoReq } from 'api/endpoints/hotspots';
import HotspotOverviewData from 'api/types/hotspots/HotspotOverview.interface';
import { STRINGS } from 'constants/strings';

const { HOTSPOTS } = STRINGS;

const MAX_LENGTH = 10001;

export const useHotspotOverview = (data: HotspotOverviewData) => {
  const queryClient = useQueryClient();

  const [memo, setMemo] = useState<string>(data.memo ?? '');
  const [isMemoValidationError, setIsMemoValidationError] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation(updateMemoReq, {
    onSuccess: (response) => {
      queryClient.setQueryData(['hotspotOverview'], { ...data, memo: response });
    },
  });

  const handleChangeMemo = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      const length = value.length;

      setMemo(value);

      const isValid = length < MAX_LENGTH;

      if (isValid) {
        if (isMemoValidationError) {
          setIsMemoValidationError(false);
        }
      } else setIsMemoValidationError(true);
    },
    [isMemoValidationError],
  );

  const handleSaveMemo = useCallback(() => {
    if (!isMemoValidationError) mutate({ memo, hotspot: data.address });
  }, [data.address, memo, mutate, isMemoValidationError]);

  const helperText = isMemoValidationError ? HOTSPOTS.MEMO_ERROR : ' ';

  return {
    memo,
    isLoading,
    helperText,
    handleSaveMemo,
    handleChangeMemo,
    isMemoValidationError,
  };
};
