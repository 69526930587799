export const STRINGS = {
  MENU: {
    HOTSPOT: 'Hotspot',
    OPEN_SEARCH: 'OpenSearch',
  },
  SIGN_IN: {
    RAK_ID: 'Sign in with RAK ID',
    ACCOUNT: 'Sign in to your account',
    AUTO_LOGOUT_BUTTON: 'Stay signed in',
    DESCRIPTION: 'Analyze, monitor and provide better help with WisHM Portal',
    COPYRIGHT: '© 2020 RAKwireless Technology Co., Ltd. All Rights Reserved',
    RAK_DESCRIPTION: `If you don't have the RAK ID yet - ask your Support Team Coordinator for one. Learn more about`,
    PERMISSIONS:
      'You don’t have permission to authorize in WisHM Portal. Contact your supervisor to obtain one. If you should have had access, make sure you are using your working RAK ID account.',
    AUTO_LOGOUT_TEXT:
      "You've been inactive for more than 15 minutes. For your security, we'll automatically sign you out in less than one minute. Choose 'Stay signed in' to continue.",
  },
  NOT_FOUND: 'This page not found',
  HOTSPOTS: {
    TABS: {
      OVERVIEW: 'Overview',
      HOTSPOT: 'Hotspot',
      BLOCKCHAIN: 'Blockchain',
    },
    LAST_SEEN: 'Last seen',
    SSH_TERMINAL: 'SSH Terminal',
    SSH_TERMINAL_TEXT: 'Run and execute command on this gateway via a terminal',
    PUBLIC_ADDRESS: 'Public address',
    SEARCH_PLACEHOLDER: 'Type in the Public Address',
    SEARCH_ERROR: 'Please enter from 50 to 60 symbols for a Public Address search',
    MEMO_ERROR: 'You have exceeded the maximum character limit for the memo.',
    MEMO_PLACEHOLDER:
      'You can add a memo related to this specific hotspot here. Keep in mind, that it would be visible to anyone across WisHM Portal. It can be edited afterwards.',
  },
  COMMON: {
    OK: 'Ok',
    SAVE: 'Save',
    NO_RESULTS: 'No results',
  },
  ERROR: {
    SOMETHING_WENT_WRONG: 'Server is unavailable. Please try again later',
  },
  SSH_TERMINAL: {
    TITLE: 'SSH Terminal',
    RECONNECT: 'Reconnect',
    SEND: 'Send to terminal',
    FORBIDDEN_INPUT_FORMAT: 'Forbidden input format.',
    PERMISSION_DENIED: 'You don’t have permission to establish SSH terminal',
    PLACEHOLDER: 'Paste your command(s) here and hit enter to send to console.',
    SSH_CONNECTION_ERROR:
      'SSH terminal cannot be established. The hotspot might be offline. Please make sure you attempt to connect under the RAK corporate IP',
  },
};
