import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

interface Props {
  text: string;
  isOpen: boolean;
  disabled?: boolean;
  buttonTitle: string;
  onClick: VoidFunction;
  maxWidth?: DialogProps['maxWidth'];
}

const SimpleDialog = ({ isOpen, maxWidth = 'xs', disabled, onClick, text, buttonTitle }: Props) => (
  <Dialog open={isOpen} maxWidth={maxWidth}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
      <Typography color="secondary" align="center">
        {text}
      </Typography>
      <Button
        onClick={onClick}
        variant="contained"
        disabled={disabled}
        sx={{ width: '200px', mt: 4 }}
      >
        {buttonTitle}
      </Button>
    </Box>
  </Dialog>
);

export default SimpleDialog;
